<template>
  <a-modal :visible="visible" :closable="false" :width="500">
    <h5 class="mt-2 text-center">
      Atur Produk
    </h5>
    <div>
      <div>Apakah Anda ingin mendaftarkan seluruh produk Anda ke gudang: <b>{{ form?.name || 'ini' }}</b>?</div>
      <a-radio-group v-model="selectedOption" class="mt-3 w-100">
        <a-card :body-style="{ padding: '10px 10px' }">
          <a-radio value="all" class="w-100">
            Ya, semua produk
          </a-radio>
        </a-card>
        <a-card class="mt-2" :body-style="{ padding: '10px 10px' }">
          <a-radio disabled value="some" class="w-100">
            Tidak, hanya beberapa produk <a-tag>Coming Soon</a-tag>
          </a-radio>
        </a-card>
        <template v-if="isWarehouseForm">
          <a-divider><span style="font-size: 12px;">Atau</span></a-divider>
          <a-card class="mt-2" :body-style="{ padding: '10px 10px' }">
            <a-radio value="later" class="w-100 d-flex align-items-center">
              <div>
                <div>Atur Nanti</div>
                <div style="font-size: 10px; font-style: italic; color: #407BA7;">
                  Anda dapat mengatur produk melalui menu <b>Daftar Gudang &#8594; Atur Produk</b>
                </div>
              </div>
            </a-radio>
          </a-card>
        </template>
      </a-radio-group>
    </div>
    <template #footer>
      <a-button v-if="!isWarehouseForm" key="back" @click="closeModal()">
        Batal
      </a-button>
      <a-button key="submit" :disabled="!selectedOption" type="primary" @click="onSubmitModalConfirmationAdjustmentProduct()">
        Lanjut
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { addProductToWarehouse, checkRequestStatusAddProductToWarehouse } from '@/api/inventory'

export default {
  name: 'ModalConfirmationAdjustmentProduct',
  props: {
    visible: Boolean,
    back: Function,
    form: Object,
    whsId: String,
  },
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    isWarehouseForm() {
      const listRouteName = ['warehouse-update', 'warehouse-add']
      return Boolean(listRouteName.includes(this.$route.name));
    },
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.selectedOption = null
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('handleModal', false)
    },
    submitModal() {
    },
    intervalCheckRequestStatusAddProductToWarehouse(idRequest) {
      const interval = setInterval(() => {
        checkRequestStatusAddProductToWarehouse({
          params: {
            process_name: 'ADD_WAREHOUSE',
          },
          id_request: idRequest,
        })
          .then(({ data }) => {
            if (data?.status === 'FINISHED') {
              this.closeModal()
              clearInterval(interval);
              this.$notification.success({
                message: 'Produk berhasil ditambahkan',
              })
              return this.$store.commit('products/SET_STATE_ADJUST_WAREHOUSE_PRODUCT', {
                showModalAdjustWarehouseProduct: false,
                loadingAdjustWarehouseProduct: false,
                backgroundNotificationAdjustWarehouseProduct: false,
              })
            }
          })
          .catch(error => {
            clearInterval(interval);
            this.$notification.error({
              message: 'Gagal',
              description: 'Terjadi kesalahan saat menambahkan produk ke gudang.',
            });
            this.$store.commit('products/SET_STATE_ADJUST_WAREHOUSE_PRODUCT', {
              showModalAdjustWarehouseProduct: false,
              loadingAdjustWarehouseProduct: false,
              backgroundNotificationAdjustWarehouseProduct: false,
            })
            console.error(error);
          });
      }, 3000);
    },
    onSubmitModalConfirmationAdjustmentProduct() {
      if (this.selectedOption === 'all') {
        if (!this.whsId) {
          return this.$notification.error({
            message: 'Warehouse id tidak ditemukan',
          })
        }
        this.$emit('handleModal', false)
        this.$store.commit('products/SET_STATE_ADJUST_WAREHOUSE_PRODUCT', {
          showModalAdjustWarehouseProduct: true,
          loadingAdjustWarehouseProduct: true,
        })
        const payload = {
            business_id: this.$route.query.business_id,
            warehouse_ids: [this.whsId],
        }
        addProductToWarehouse(payload)
        .then(({ data }) => {
          this.intervalCheckRequestStatusAddProductToWarehouse(data?.process_id)
          if (this.isWarehouseForm) {
            this.$router.push({
              path: '/warehouse',
              query: this.$route.query,
            })
          }
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Terjadi kesalahan saat menambahkan produk ke gudang',
          })
          this.$store.commit('products/SET_STATE_ADJUST_WAREHOUSE_PRODUCT', {
            showModalAdjustWarehouseProduct: false,
            loadingAdjustWarehouseProduct: false,
            backgroundNotificationAdjustWarehouseProduct: false,
          })
          console.error(err)
        })
      } else {
        this.closeModal()
        this.$router.push({
          path: '/warehouse',
          query: this.$route.query,
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
</style>