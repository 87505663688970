<template>
  <div class="pbz-font">
    <a-row class="d-flex align-items-center">
      <a-col :span="1" class="d-flex justify-content-start">
        <div style="cursor: pointer">
          <router-link
            :to="to(`/warehouse`)"
          >
            <ArrowIcon
              class="ant-dropdown-link"
              style="color: #E00000; height: 100px; width: 25px"
              :rotate="'rotate(270)'"
            />
          </router-link>
        </div>
      </a-col>
      <div class="pbz-font body-md-bold mr-3">
        {{ isEdit ? $t('warehouse.edit_warehouse') : $t('warehouse.add_warehouse') }}
      </div>
      <a-col
        class="d-flex justify-content-start align-items-center"
        :span="10"
        style="border-left: 1px solid rgb(204, 204, 204)"
      >
        <div
          class="ml-4"
          style="color: #006BD1; margin-right: 5px"
        >
          {{ $t('warehouse.list_warehouse') }} >
        </div>
        {{ isEdit ? $t('warehouse.edit_warehouse') : $t('warehouse.add_warehouse') }}
      </a-col>
    </a-row>

    <div class="add-distributor-container">
      <a-form-model ref="ruleForm" class="mb-4" :model="form">
        <a-row>
          <a-col :span="24" class="mb-2">
            <a-row class="input-warehouse-container px-5 py-3 bg-white rounded-lg">
              <div class="pbz-font body-md-bold mb-4 mt-2">
                {{ isEdit ? $t('warehouse.edit_warehouse') : $t('warehouse.add_warehouse') }}
              </div>
              <a-col :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="name"
                  :label="'Nama Gudang'"
                  :rules="[
                    {
                      required: true,
                      message: 'Nama Gudang Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.name"
                    class="input"
                    placeholder="Nama gudang"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="warehouse_code"
                  :label="'Kode Gudang'"
                  :rules="[
                    {
                      required: true,
                      message: 'Kode Gudang Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.warehouse_code"
                    class="input"
                    placeholder="Kode gudang"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="ext_code"
                  :label="'Kode Eksternal'"
                  :rules="[
                    {
                      required: true,
                      message: 'Kode Eksternal Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.ext_code"
                    class="input"
                    placeholder="Kode eksternal"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="contact_name"
                  :label="'PIC'"
                  :rules="[
                    {
                      required: true,
                      message: 'PIC Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.contact_name"
                    class="input"
                    placeholder="PIC"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="contact_phone"
                  :label="'Nomor Handphone'"
                  :rules="[
                    {
                      required: true,
                      message: 'Nomor Handphone Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.contact_phone"
                    class="select-input"
                    placeholder="No. Handphone"
                  >
                    <a-select
                      slot="addonBefore"
                      class="select-phone-code"
                      option-label-prop="children"
                      value="+62"
                    >
                      <a-select-option
                        v-for="(item, index) in codeCountry"
                        :key="index"
                        :value="item.value"
                        :label="item.value"
                        class="d-flex"
                      >
                        <keep-alive>
                          <component :is="flagComponent(item.value)" />
                        </keep-alive>
                        <p style="margin-left: 10px">
                          {{ item.label }}
                        </p>
                      </a-select-option>
                    </a-select>
                  </a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row class="mt-3 input-warehouse-container px-5 py-3 bg-white rounded-lg">
              <div class="pbz-font body-md-bold mb-4 mt-2">
                {{ $t('warehouse.address_warehouse') }}
              </div>
              
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item
                    prop="country_id"
                    label="Negara"
                    :rules="[{
                      required: true,
                      message: 'Negara Tidak Boleh Kosong',
                    }]"
                  >
                    <a-select
                      v-model="form.country_id"
                      show-arrow
                      show-search
                      :filter-option="filterOption"
                    >
                      <a-select-option v-for="country in countries" :key="country.value" :value="country.value" :title="country.label">
                        {{ country.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    prop="city_id"
                    label="Kota"
                  >
                    <a-select
                      v-model="form.city_id"
                      show-arrow
                      show-search
                      :filter-option="filterOption"
                      :not-found-content="loadingCities ? undefined : null"
                      :disabled="!(!!form.country_id)"
                      @change="onChangeCity"
                      @focus="getCities"
                    >
                      <a-spin v-if="loadingCities" slot="notFoundContent" size="small" />
                      <a-select-option v-for="city in cities" :key="city.value" :value="city.value" :title="city.label">
                        {{ city.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item
                    prop="subdistrict_id"
                    label="Kecamatan"
                  >
                    <a-select
                      v-model="form.subdistrict_id"
                      show-arrow
                      show-search
                      :filter-option="filterOption"
                      :disabled="!(!!form.city_id)"
                      :not-found-content="loadingSubdistrict ? undefined : null"
                      @change="onChangeDistrict"
                      @focus="getSubdistrict"
                    >
                      <a-select-option
                        v-if="!form.city_id"
                        key="novalid"
                        value=""
                        disabled
                        class="text-dark"
                      >
                        Harap Pilih Kota
                      </a-select-option>
                      <a-spin v-else-if="loadingSubdistrict && form.city_id" slot="notFoundContent" size="small" />
                      <a-select-option v-for="sub in subdistricts" :key="sub.value" :value="sub.value" :title="sub.label">
                        {{ sub.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    label="Kode Pos"
                  >
                    <a-input
                      v-model="form.postal_code"
                      read-only
                      :disabled="!(!!form.subdistrict_id)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-form-model-item
                prop="street_name"
                label="Alamat"
                :rules="[
                  {
                    required: true,
                    message: 'Alamat Tidak Boleh Kosong',
                  },
                ]"
              >
                <a-textarea
                  v-model="form.street_name"
                  :auto-size="{ minRows: 2, maxRows: 9 }"
                />
              </a-form-model-item>
            </a-row>

            <a-row class="mt-3 input-warehouse-container px-5 py-3 bg-white rounded-lg">
              <div class="pbz-font body-md-bold mb-4 mt-2">
                {{ $t('warehouse.integration_warehouse') }}
              </div>
              <a-col :span="24" class="d-flex justify-content-start mb-3">
                <a-radio-group
                  :value="warehouseIntegrationType"
                  class="checkbox-input text-note d-flex mb-2"
                  @change="handleWhsIntegrationType"
                >
                  <a-radio value="not_connected">
                    Tidak Terkoneksi Warehouse Management System
                  </a-radio>
                  <a-radio value="javelin">
                    Terkoneksi dengan Javelin
                  </a-radio>
                  <a-radio value="jubelio">
                    Terkoneksi dengan Jubelio
                  </a-radio>
                </a-radio-group>
              </a-col>

              <a-col v-if="warehouseIntegrationType === 'javelin'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="auth_key"
                  label="Auth Key"
                  :rules="[
                    {
                      required: true,
                      message: 'Auth Key Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.auth_key"
                    class="input"
                    placeholder="Masukan Auth Key"
                  />
                </a-form-model-item>
              </a-col>
              <a-col v-if="warehouseIntegrationType === 'javelin'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="secret_key"
                  label="Secret Key"
                  :rules="[
                    {
                      required: true,
                      message: 'Secret Key Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.secret_key"
                    class="input"
                    placeholder="Masukan Secret Key"
                  />
                </a-form-model-item>
              </a-col>
              <a-col v-if="warehouseIntegrationType === 'javelin'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="api_url_javelin"
                  label="API URL"
                  :rules="[
                    {
                      required: true,
                      message: 'API URL Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.api_url_javelin"
                    class="input"
                    placeholder="Masukan API URL"
                  />
                </a-form-model-item>
              </a-col>

              <a-col v-if="warehouseIntegrationType === 'jubelio'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="username"
                  label="Username"
                  :rules="[
                    {
                      required: true,
                      message: 'Username Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.username"
                    class="input"
                    placeholder="Masukan Username"
                  />
                </a-form-model-item>
              </a-col><a-col v-if="warehouseIntegrationType === 'jubelio'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="password"
                  label="Password"
                  :rules="[
                    {
                      required: true,
                      message: 'Password Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.password"
                    class="input"
                    type="password"
                    placeholder="Masukan Password"
                  />
                </a-form-model-item>
              </a-col>
              <a-col v-if="warehouseIntegrationType === 'jubelio'" :span="24" class="d-flex flex-column">
                <a-form-model-item
                  prop="api_url_jublio"
                  label="API URL"
                  :rules="[
                    {
                      required: true,
                      message: 'API URL Tidak Boleh Kosong',
                    },
                  ]"
                >
                  <a-input
                    v-model="form.api_url_jublio"
                    class="input"
                    placeholder="Masukan API URL"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="mt-3 bg-white footer-container d-flex py-3 px-5 align-items-center">
          <a-col :span="16">
            <div class="d-flex">
              <div v-for="(item, index) in step_form" :key="index" :class="`d-flex align-items-center ${item.status ? 'step-container-active' : 'step-container'}`">
                <div class="number px-3 py-1 rounded-lg">
                  {{ index + 1 }}
                </div>
                <div>{{ item.name }}</div>
                <i v-if="index+1 < step_form.length" class="icon-right-arrow font-size-24 mr-2" />
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="12">
                <a-button class="cancel-button" :disabled="loading">
                  <router-link
                    :to="to('/warehouse')"
                  >
                    Batal
                  </router-link>
                </a-button>
              </a-col>
              <a-col :span="12">
                <a-button class="submit-button px-4" :loading="loading" @click="handleSubmit">
                  {{ isEdit ? 'Submit' : 'Tambah Gudang' }}
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <ModalConfirmationAdjustmentProduct
      :form="form"
      :visible="showModalConfirmationAdjustmentProduct"
      :whs-id="responseAddWarehouseId"
      @handleModal="handleShowModalConfirmationAdjustmentProduct"
    />
  </div>
</template>

<script>
import { getArea } from '@/api/data'
import { getWarehouseById, crudWarehouseInternal } from '@/api/warehouse'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import IndonesiaFlag from '@/components/Icons/IndonesiaFlag.vue'
import MalaysiaFlag from '@/components/Icons/MalaysiaFlag.vue'
import useQueryParam from '@/composable/useQueryParam'
import ModalConfirmationAdjustmentProduct from '@/views/setting/warehouse/ModalConfirmationAdjustmentProduct.vue'

export default {
  components: {
    ArrowIcon,
    IndonesiaFlag,
    MalaysiaFlag,
    ModalConfirmationAdjustmentProduct,
  },
  setup() {
    const { to } = useQueryParam()

    return { to }
  },
  data() {
    return {
      form: {
        name: '',
        warehouse_code: '',
        ext_code: '',
        contact_name: '',
        contact_phone: '',
        country_id: '',
        city_id: '',
        subdistrict_id: '',
        postal_code: '',
        street_name: '',
        auth_key: '',
        secret_key: '',
        api_url_javelin: '',
        username: '',
        password: '',
        api_url_jublio: '',
      },
      warehouseIntegrationType: 'not_connected',
      codeCountry: [
        {
          label: '+62',
          value: '+62',
        },
        // {
        //   label: '+60',
        //   value: '+60',
        // },
      ],
      countries: [
        { value: "228", label: "Indonesia", code: "ID", currency_code: "IDR", currency_id: "111" },
      ],
      cities: [],
      subdistricts: [],
      distributors: [],
      loadingCities: true,
      loadingSubdistrict: true,
      loadingDistributor: true,
      loading: false,
      step_form: [
        {
          name: this.$t('warehouse.information_warehouse'),
          status: false,
        },
        {
          name: this.$t('warehouse.address_warehouse'),
          status: false,
        },
        {
          name: this.$t('warehouse.integration_warehouse'),
          status: false,
        },
      ],
      showModalConfirmationAdjustmentProduct: false,
      responseAddWarehouseId: null,
    }
  },
  computed: {
    isEdit() {
      return Boolean(this.$route.params.id)
    },
  },
  mounted() {
    if (this.isEdit) this.getDetailWarehouseInternal(this.$route.params.id)
  },
  methods: {
    handleShowModalConfirmationAdjustmentProduct(param) {
      this.showModalConfirmationAdjustmentProduct = param
    },
    async getDetailWarehouseInternal(id) {
      await getWarehouseById(id)
      .then(({ data: { data: response } }) => {
        this.form = {
          ...this.form,
          ...response,
          name: response?.name,
          warehouse_code: response?.warehouse_code,
          ext_code: response?.ext_code,
          contact_name: response?.warehouse_contact,
          contact_phone: response?.phone,
          postal_code: response?.code_pos,
          street_name: response?.address_line?.replace(' null', ''),
          auth_key: response?.wms_code === 'javelin' ? response?.param1 : '',
          secret_key: response?.wms_code === 'javelin' ? response?.param2 : '',
          api_url_javelin: response?.wms_code === 'javelin' ? response?.param3 : '',
          username: response?.wms_code === 'jubelio' ? response?.param1 : '',
          password: response?.wms_code === 'jubelio' ? response?.param2 : '',
          api_url_jublio: response?.wms_code === 'jubelio' ? response?.param3 : '',
        }
        const currentCountryId = this.countries?.find((obj) => obj.label === response?.country)?.value
        if (currentCountryId) {
          this.form.country_id = currentCountryId
          this.getCities(currentCountryId)
        }
        this.warehouseIntegrationType = response?.wms_code
          ? response?.wms_code
          : 'not_connected'
      })
      .catch((err) => {
        this.form = {
          name: '',
          warehouse_code: '',
          ext_code: '',
          contact_name: '',
          contact_phone: '',
          country_id: '',
          city_id: '',
          subdistrict_id: '',
          postal_code: '',
          street_name: '',
          auth_key: '',
          secret_key: '',
          api_url_javelin: '',
          username: '',
          password: '',
          api_url_jublio: '',
        }
        this.$notification.error({
          message: err?.response?.data?.message || 'id not found',
        })
      })
    },
    phoneRule(e) {
      if (/^\D$/.test(e.key)) {
        e.preventDefault()
      }
    },
    flagComponent(param) {
      if (param === '+62' || param === 'Indonesia') return IndonesiaFlag
      if (param === '+60') return MalaysiaFlag
    },
    handleWhsIntegrationType(value) {
      this.warehouseIntegrationType = value?.target?.value
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate('auth_key')
        this.$refs.ruleForm.clearValidate('secret_key')
        this.$refs.ruleForm.clearValidate('api_url_javelin')
        this.$refs.ruleForm.clearValidate('username')
        this.$refs.ruleForm.clearValidate('password')
        this.$refs.ruleForm.clearValidate('api_url_jublio')
      }
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const { name, warehouse_code, contact_phone, contact_name, country_id, city_id, subdistrict_id, street_name, auth_key, secret_key, api_url_javelin, username, password, api_url_jublio, ext_code, postal_code } = this.form
          const provinceName = this.cities?.find((obj) => obj.value === city_id)?.label?.split(' - ')
          const districtName = this.subdistricts?.find((obj) => obj.value === subdistrict_id)?.label?.split(' - ')
          let type = 'save'
          let payload = {
            name,
            code: warehouse_code,
            contact_phone,
            contact_name,
            business_id: this.$route.query.business_id,
            ext_code,
            address : {
              country_id,
              country_name: this.countries?.find((obj) => obj.value === country_id)?.label,
              province_name: provinceName?.[1] || '',
              city_id,
              city_name: provinceName?.[0] || '',
              district_id: subdistrict_id,
              district_name: districtName?.[1] || '',
              sub_district_name: districtName?.[0] || '',
              postal_code,
              street_name,
            },
            wms_categories: this.warehouseIntegrationType === 'not_connected' ? 'SIMPLE INVENTORY' : 'EXTERNAL WMS',
          }

          if (this.warehouseIntegrationType !== 'not_connected') {
            payload = {
              ...payload,
              wms_code: this.warehouseIntegrationType,
              param1: this.warehouseIntegrationType === 'javelin' ? auth_key : username,
              param2: this.warehouseIntegrationType === 'javelin' ? secret_key : password,
              param3: this.warehouseIntegrationType === 'javelin' ? api_url_javelin : api_url_jublio,
            }
          }

          if (this.isEdit) {
            payload.id = this.$route.params.id
            type = 'update'
          }

          await crudWarehouseInternal({ type, data: payload })
          .then(({ data: { data: res } }) => {
            if (res?.id) {
              this.responseAddWarehouseId = res?.id
              this.$notification.success({
                message: 'Data Berhasil di Simpan',
              })
              if (type === 'save') {
                this.showModalConfirmationAdjustmentProduct = true
              } else {
                this.$router.push({
                  path: '/warehouse',
                  query: this.$route.query,
                })
              }
            }
          })
          .catch((err) => {
            this.$notification.error({
              message: err.response.data.message,
            })
          })
        }
      })
    },
    async getCities(id) {
      if (this.loadingCities) {
        await getArea({
          businessId: this.$route.query.business_id,
          params: { parentId: id ?? '228' },
        })
        .then(res => {
          const list = res?.data?.list
          if (list) {
            this.cities = list
            this.loadingCities = false
            if (this.$route.params.id) {
              const currentCityId = list?.find((obj) => obj.label === `${this.form.city} - ${this.form.province}`)?.value
              if (currentCityId) {
                this.form.city_id = currentCityId
                this.getSubdistrict(currentCityId)
              }
            }
          }
        })
        .catch(() => {})
      }
    },
    async getSubdistrict(id) {
      if (this.loadingSubdistrict && this.form.city_id) {
        await getArea({
          businessId: this.$route.query.business_id,
          params: { parentId: id ?? this.form.city_id },
        })
        .then(res => {
          const list = res?.data?.list
          if (list) {
            this.subdistricts = list
            this.loadingSubdistrict = false
            if (this.$route.params.id) {
              const currentSubdistrictId = list?.find((obj) => obj.label === `${this.form.sub_district} - ${this.form.district}`)?.value
              if (currentSubdistrictId) {
                this.form.subdistrict_id = currentSubdistrictId
              }
            }
          }
        })
        .catch(() => {})
      }
    },
    onChangeCity() { // val, option
      this.subdistricts = []
      this.loadingSubdistrict = true
      this.form.subdistrict_id = ''
      this.form.postal_code = ''
    },
    onChangeDistrict(e) { // val, option
      const findSelectedSubdistrict = this.subdistricts?.find((obj) => obj.value === e)
      this.form.postal_code = findSelectedSubdistrict?.postcode
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>

<style lang="scss" scoped>
.add-distributor-container {
  .border-distributor {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }
  .submit-button {
    background: #E00000;
    color: #fff;
    border-radius: 5px;
  }
  .cancel-button {
    width: 100%;
    color: #E00000;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
  }

}
.footer-container {
  position: sticky;
  bottom: 0;
  margin: 0px -35px -20px -35px;
}
.step-container {
  gap: 0px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #878F97;
  .number {
    background-color: #F4F5F6;
  }
}
.step-container-active {
  gap: 0px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #2196F3;
  .number {
    background-color: #E3F2FD;
  }
}
</style>

<style lang="scss">
  .input-warehouse-container {
    border-radius: 8px;

    .warehouse-list {
      border: 1px solid #CCCCCC;
      border-radius: 8px;
      text-align: left;
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .label-form {
      position: absolute;
      left: 15px;
      top: -11px;
      background: #FFFFFF;
      color: #B3B3B3;
    }

    .tag-box {
      display: inline-flex;
      position: static;
      width: auto;
      height: 31px;
      background: #4D4D4D;
      box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
      border-radius: 33px;
      margin: 0 5px;
      padding: 5px 15px;
      p {
        position: static;
        color: #ffffff;
        align-items: center;
        text-align: center;
      }
    }
    .x-button {
      color:#ffffff;
      background:#4D4D4D;
      border:none;
      line-height: 21px;
      padding: 0 0 5px 7px;
    }
    .input {
      max-height: 48px;
      min-height: 30px;
    }
    .select-input {
      input:focus {
        border: 1px solid #4b7cf3;
      }
      .ant-input {
        border: 1px solid #e4e9f0;
        border-left: none;
      }
      .ant-select-selection.ant-select-selection--single {
        border-bottom-right-radius: 0;
        background-color: #ffffff;
        border: 1px solid #e4e9f0;
        border-right: none;
        width: 100px;
        margin-top: -36px !important;
      }
      .ant-select-selection-selected-value {
        margin-top: 6px;
        display: flex !important;
      }
      form .ant-input-group .ant-select {
        height: 0px;
      }
      .ant-input-group-addon {
        background-color: transparent !important;
        border: none !important;
      }
      .ant-input-group-addon:first-child .ant-select .ant-select-selection {
        margin-top: 5px !important;
      }
    }
    .checkbox-input {
      gap: 4px 0px;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #E00000;
        border-color: #E00000;
      }
    }
    .radio-input {
      .ant-radio-checked .ant-radio-inner {
        border-color: #E00000;
      }
      .ant-radio-inner::after{
        background-color: #E00000;
      }
    }
    .submit-button {
      background: #E00000;
      color: #FFFFFF;
      border-radius: 5px;
    }
    .cancel-button {
      background: #FFFFFF;
      border: 1px solid #E00000;
      color: #E00000;
      border-radius: 5px;
    }
    .text-select {
      font-weight: 500;
      font-size: 16px;
    }
    .text-note {
      font-weight: 400;
      font-size: 1em;
      color: #999999;
      text-align: left;
      margin-top: 5px;
    }
    .button-input {
      width: 90%;
      height: 48px;
      background: #E00000;
      color: #FFFFFF;
      font-weight: 800;
    }
    .ant-select-selection__placeholder {
      color: #999999;
      margin-top: 0px;
    }
  }
  .select-phone-code {
    .ant-select-selection__rendered {
      line-height: 20px;
    }
  }
</style>
