<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 12H0V4.55169C0 4.32317 0.185297 4.13788 0.413812 4.13788H23.5862C23.8147 4.13788 24 4.32317 24 4.55169L24 12Z" fill="#C8414B"/>
<path d="M0 12H24V19.4483C24 19.6768 23.8147 19.8621 23.5861 19.8621H0.413812C0.185297 19.8621 0 19.6768 0 19.4483V12Z" fill="#F5F5F5"/>
</svg>

  <!-- <svg viewBox="0 0 140 100" width="40" height="32" xmlns="http://www.w3.org/2000/svg">
    <path d="m12 27.751h104v72.497h-104z" fill="#d60a2e"/>
    <path d="m12 64h104v36.249h-104z" fill="#f0f0f0"/>
  </svg> -->
</template>

<script>
export default {
  name: 'IndonesiaFlag',
}
</script>
