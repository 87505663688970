<template>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 5.26111H0V4.55175C0 4.32324 0.185297 4.13794 0.413812 4.13794H23.5862C23.8147 4.13794 24 4.32324 24 4.55175L24 5.26111Z" fill="#FF4B55"/>
<path d="M24 5.2608H0V6.38393H24V5.2608Z" fill="#F5F5F5"/>
<path d="M24 6.3844H0V7.50752H24V6.3844Z" fill="#FF4B55"/>
<path d="M24 7.50708H0V8.6302H24V7.50708Z" fill="#F5F5F5"/>
<path d="M24 8.63068H0V9.7538H24V8.63068Z" fill="#FF4B55"/>
<path d="M24 9.75378H0V10.8769H24V9.75378Z" fill="#F5F5F5"/>
<path d="M24 10.8769H0V12H24V10.8769Z" fill="#FF4B55"/>
<path d="M24 12H0V13.1231H24V12Z" fill="#F5F5F5"/>
<path d="M24 14.2463H0V12.4138H0.413812L0.413906 13.1231H24V14.2463Z" fill="#FF4B55"/>
<path d="M24 14.2463H0V15.3694H24V14.2463Z" fill="#F5F5F5"/>
<path d="M24 15.3694H0V16.4925H24V15.3694Z" fill="#FF4B55"/>
<path d="M24 16.493H0V17.6161H24V16.493Z" fill="#F5F5F5"/>
<path d="M24 17.6157H0V18.7388H24V17.6157Z" fill="#FF4B55"/>
<path d="M23.5861 19.8621H0.413812C0.185297 19.8621 0 19.6768 0 19.4482V18.7389H24V19.4482C24 19.6768 23.8147 19.8621 23.5861 19.8621Z" fill="#F5F5F5"/>
<path d="M14.4828 13.1232H0.413812C0.185297 13.1232 0 12.9379 0 12.7093V4.55175C0 4.32324 0.185297 4.13794 0.413812 4.13794H14.4828C14.7113 4.13794 14.8966 4.32324 14.8966 4.55175V12.7093C14.8965 12.938 14.7113 13.1232 14.4828 13.1232Z" fill="#41479B"/>
<path d="M3.93101 8.62606C3.93101 7.06105 5.20132 5.79154 6.76488 5.79154C7.1583 5.79154 7.53236 5.87207 7.87305 6.01719C7.91725 6.03604 7.95058 5.97449 7.91088 5.9474C7.34721 5.56368 6.65786 5.35344 5.91846 5.39188C4.29288 5.4764 2.96365 6.78575 2.85856 8.41016C2.73635 10.2996 4.23148 11.8648 6.091 11.8648C6.763 11.8648 7.38766 11.6577 7.90642 11.3067C7.94636 11.2797 7.91327 11.218 7.86888 11.2368C7.52931 11.3808 7.15675 11.4607 6.76497 11.4607C5.20132 11.4606 3.93101 10.1911 3.93101 8.62606Z" fill="#FFE15A"/>
<path d="M10.4091 8.62607L11.6071 8.16252C11.6663 8.1396 11.6461 8.05115 11.5828 8.05621L10.3028 8.15873L11.1824 7.21842C11.2258 7.17206 11.1693 7.10123 11.1144 7.1332L10.0027 7.78176L10.3864 6.55476C10.4053 6.4942 10.3237 6.45487 10.2882 6.50746L9.56846 7.57363L9.38382 6.30107C9.37472 6.23822 9.28397 6.23817 9.27478 6.30103L9.15685 7.10624L9.10791 7.44703L9.08907 7.57368L8.36935 6.5084C8.33382 6.45581 8.25207 6.49518 8.27115 6.55575L8.65627 7.78171L7.54458 7.13315C7.48974 7.10114 7.43321 7.17201 7.47662 7.21837L8.35622 8.15868L7.07584 8.05617C7.01251 8.0511 6.99231 8.13956 7.05155 8.16248L8.25066 8.62602L7.05151 9.08966C6.99221 9.11258 7.01246 9.20104 7.07579 9.19598L8.35622 9.09346L7.47657 10.0361C7.43321 10.0826 7.48988 10.1533 7.54468 10.1212L8.65632 9.47038L8.27119 10.6963C8.25216 10.7569 8.33382 10.7963 8.3694 10.7437L9.08911 9.67841L9.10796 9.80807L9.15689 10.1458L9.27488 10.9532C9.28407 11.0161 9.37482 11.016 9.38391 10.9532L9.56855 9.67851L10.2883 10.7447C10.3238 10.7973 10.4055 10.758 10.3865 10.6974L10.0028 9.47038L11.1144 10.1212C11.1692 10.1533 11.2258 10.0826 11.1825 10.0361L10.3028 9.09346L11.5829 9.19598C11.6462 9.20104 11.6664 9.11258 11.6072 9.08966L10.4091 8.62607Z" fill="#FFE15A"/>
</svg>
</template>

<script>
export default {
  name: 'MalaysiaFlag',
}
</script>
